<template>
<div class="banner w-full bg-pattern-bg">
    <div class="hidden md:block ">
        <div class="flex justify-center items-center max-w-screen-xl mx-auto">
            <img class="mx-auto object-cover md:px-0 lg:px-20 pt-24 pb-10" src="../images/banner0831.png" alt="">
        </div>
    </div>
</div>
    <img class="mobile-banner md:hidden pt-14" src="../images/banner_mobile.jpg" alt="">
</template>

<script>
export default {
    name: 'travelBanner'
}
</script>