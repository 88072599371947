<template>
<div class="fun max-w-screen-2xl flex justify-center mx-auto">
    <div class="max-w-screen-lg mb-20">
        <h1 class="pt-28 md:pt-44 text-center text-ntpccolor text-2xl md:text-3xl font-semibold">
            活動召集令，大獎任你拿
        </h1>
        <p class="w-11/12 md:w-2/3 mx-auto pt-6 text-footerlink">
            逛古蹟還有機會抽大獎？沒錯就是這麼eazy，只要參加有獎徵答、臉書抽獎或小遊戲過關，各種精美的限量好禮就讓你帶回家！
        </p>
        <div class="px-4 md:px-12">
            <div class=" relative mt-10 overflow-hidden">
                <span class="absolute bottom-1 left-1 text-xs text-white">力麗慈善事業基金會收錄</span>
                <img class="w-full object-cover h-470 overflow-hidden " src="../images/fun/fun_home.jpg" alt="">
            </div>
            <h2 class=" pt-6 text-ntpccolor text-lg font-semibold">新北尋蹟有獎徵答</h2>
            <p class="pt-2 text-justify">
                力麗社會福利慈善基金會及交通部臺灣鐵路管理局共同舉辦「畫說台鐵與台灣建築遺產展」，110年首度移師新北市。本市配合於全國古蹟日期間舉辦文資小旅行，邀民眾從畫作走入實景，共賞新北文資之美。文化局FB粉專同步推出有獎徵答活動，展覽限定畫冊、 《尋蹟食旅》、手機架鑰匙圈等好禮等你來拿~
            </p>
            <hr class=" mt-5 border-gray-300">
            <div class="flex items-center py-2">
                <SpeakerphoneIcon class="h-6 text-ntpcactive" />
                <a href="https://www.facebook.com/e7summer/" target="_blank">
                    <span class="pl-3 text-sm cursor-pointer text-ntpccolor hover:text-ntpcactive font-semibold underline">參加活動GO！</span>
                </a>
            </div>
        </div>

        <!------ for in ------>
        <div class="px-4 md:px-12 pt-24 flex justify-center mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-20 w-full">
                <div v-for="(fun, index) in Funs" :key="index" class="overflow-hidden">
                    <img :src="fun.img" alt="" class="overflow-hidden object-cover h-300 md:h-400 w-full">
                    <div class="relative md:h-300">
                        <h2 class="pt-6 text-ntpccolor text-lg font-semibold" v-html="fun.title"></h2>
                        <p class=" text-justify pt-3">{{ fun.text }}</p>
                        <div class="md:absolute bottom-0 w-full">
                            <hr class="mt-5 border-gray-300">
                            <div class="flex items-center py-2">
                                <SpeakerphoneIcon class="h-6 text-ntpcactive" />
                                <a :href="fun.link" target="_blank" rel="noopener noreferrer">
                                    <span class="pl-3 text-sm cursor-pointer text-ntpccolor hover:text-ntpcactive font-semibold underline">{{ fun.icontext }}</span>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<hr class="border-gray-300">
<div class=" clear-both max-w-screen-2xl flex justify-center mx-auto mb-40">
    <div class="max-w-screen-lg w-full px-4 md:px-12">
        <h2 class="text-center text-ntpccolor text-2xl md:text-3xl font-semibold mt-20 mb-10">來看更多新北奇蹟</h2>
        <div class="grid grid-cols-1 md:grid-rows-1 md:grid-cols-3 gap-6 w-full">
            <div class="">
                <router-link :to="{ name: 'Travel' }" class="group">
                <img src="../images/travel/travel_home.jpg" alt="" class="overflow-hidden object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">綜覽新北</p>
                        <h2 class=" py-2 text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">360度實境環景六大路線</h2>
                    <p>手指動一動，探索古蹟360度全景影像，6大私房路線推薦！</p>
                </div>
                </router-link>
            </div>
            <div class="">
                <router-link :to="{ name: 'Explore' }" class="group">
                <img src="../images/explore/ex_home.jpg" alt="" class="overflow-hidden object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">微觀新北</p>
                        <h2 class=" py-2 text-ntpccolor group-hover:text-ntpcactive text-lg  font-semibold">近距走讀文資萬花筒</h2>
                    <p>線上互動體驗，專業帶隊深度旅行，近距離接觸古蹟的奧妙！</p>
                </div>
                </router-link>
            </div>
            <div class="">
                <router-link :to="{ name: 'Art' }" class="group">
                <div class="overflow-hidden h-280">
                    <img src="../images/art/art_mobile.jpg" alt="" class="overflow-hidden bg-cover h-auto w-full">
                </div>
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">藝探新北</p>
                        <h2 class=" py-2 text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">精彩策展古蹟嘉年華</h2>

                    <p>古蹟日重量級展演情報，不可錯過的精彩演出。</p>
                </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { SpeakerphoneIcon } from '@heroicons/vue/solid'
export default {
    name: 'Art',
    components: { SpeakerphoneIcon },
    setup() {
        const Funs = [
            {
                img: './d_img/fun/fun2.jpg',
                title: '「線上虛擬旅行—在家遊八里」線上任務',
                text: '110/9/11(六)、9/12(日) 、9/18(六) 、9/19(日)至十三行博物館FB粉絲專頁觀看「線上虛擬旅行—在家遊八里」影片，並於9/26(日)23:59前完成小編指定任務並至活動貼文留言，標註「#2021全國古蹟日在十三行」，有機會抽中十三行建築小帆布袋加高腳屋便條紙盒(20組)、蘋果藍芽耳機AirPods搭配充電盒(2組) 。',
                icontext: '參加活動GO！',
                link: 'https://www.facebook.com/13hangmuseum/'
            },
            {
                img: './d_img/fun/fun3.jpg',
                title: '「礦業景觀線上看」臉書抽好禮',
                text: '黃金博物館自110/9/15(三)起推出「礦業景觀線上看」，於線上展示水湳洞選煉廠、本山露天礦場、小金瓜露頭等水金九地區代表性的礦業景觀，不用上山下海，在家上網即可從空中鳥瞰空拍及地面局部特寫欣賞山城美景，並可上臉書參加活動抽好禮，詳情請洽黃金博物館臉書粉專。',
                icontext: '參加活動GO！',
                link: 'https://www.facebook.com/goldmuseum/'
            },
            {
                img: './d_img/fun/fun4.jpg',
                title: '淡水古蹟光音萬花筒&emsp;抽烏克麗麗',
                text: '110/9/9(四)~9/26(日) 至淡水古蹟博物館官方FB留言抽彩繪古蹟特色烏克麗麗，琴身特別邀請藝術家陳峻儀繪製淡水紅毛城、前清淡水關稅務司官邸（小白宮）及滬尾礮臺的建築特色。',
                icontext: '參加活動GO！',
                link: 'https://www.tshs.ntpc.gov.tw/'
            },
            {
                img: './d_img/fun/fun5.jpg',
                title: '林園線上小遊戲等你來挑戰',
                text: '林園古蹟日線上走讀：帶領導覽三條路線「情字這條路」、「吃貨一條街」和「讀歲月枋橋」，介紹了包括古早的愛情產業鏈、生活飲食文化及枋橋歷史演變等脈絡，挑戰走林園走讀小遊戲，只要將您獲得的遊戲稱號至林園 FB 活動貼文分享，就有機會獲得限量 2021 林園光影藝術展【夜光小吊飾】一枚，一起來挑戰看看吧！',
                icontext: '參加活動GO！',
                link: 'https://www.facebook.com/theLinGarden'
            },
            {
                img: './d_img/fun/fun6.jpg',
                title: '空軍三重一村&emsp;懷舊知識搶答拿好禮',
                text: '空軍三重一村響應全國古蹟日，自9/11(六)至 9/26(日)止連續3周推出「懷舊知識大考驗-臉書搶答贈好禮」、 「截一片最愛的風景-臉書留言抽好禮」， 歡迎於活動期間至FB粉專參加有獎徵答及留言抽獎活動，即可獲得一村限定獎品一村眷村帆布袋(60個)或空軍數位迷彩包(20個)。',
                icontext: '參加活動GO！',
                link: 'https://www.facebook.com/militaryavillage/'
            },
            {
                img: './d_img/fun/fun7.jpg',
                title: '五股守讓堂&emsp;看影片學知識搶好禮 ',
                text: '9/18起至「五股圖書館＊五股守讓堂」臉書粉絲專頁，收看「守讓堂建築特色—綠釉花磚」線上導覽影片，並於活動貼文搶答問題，即可獲得祈福好運文具組 (30位) ，還加贈專屬茶詩書籤1張、花磚隨意貼3張(款式隨機恕無法挑選) ！',
                icontext: '參加活動GO！',
                link: 'https://www.facebook.com/WuguBranch'
            }
        ]

        return { Funs }
    }
}
</script>