<template>
<footer class="flex justify-center flex-col bg-pattern-bg">
    <div class="max-w-screen-lg w-full flex justify-center mx-auto py-16 px-8 bg-pattern-bg">
        <div class="grid grid-cols-1 gap-16 lg:grid-cols-2 lg:gap-40">
            <div class="left-col">
                <div class="flex items-center">
                    <div class="mr-12">
                        <h3 class="text-xs font-semibold text-ntpccolor">指導單位</h3>
                        <a href="https://www.ntpc.gov.tw/ch/index.jsp" target="_blank">
                            <img class="h-6 mt-5" src="../images/i1.svg">
                        </a>
                    </div>
                    <div>
                        <h3 class="text-xs font-semibold text-ntpccolor">主辦單位</h3>
                        <a href="https://www.ntpc.gov.tw/ch/index.jsp" target="_blank">
                            <img class="h-7 mt-5" src="../images/i2.svg">
                        </a>
                    </div>
                </div>
                <div>
                    <hr class="clear-both my-6 border-footerlink opacity-30">
                </div>
                <div class="">
                    <h3 class=" mt-4 text-xs font-semibold text-ntpccolor">協辦單位</h3>
                    <div class="flex items-center">
                        <div class="w-1/2 mr-28">
                            <a href="https://www.ntpc.gov.tw/ch/index.jsp" target="_blank">
                                <img class="h-6 mt-5" src="../images/si1.svg">
                            </a>
                            <a href="https://www.library.ntpc.gov.tw/" target="_blank">
                                <img class="h-5 mt-4" src="../images/si2.svg">
                            </a>
                            <a href="https://www.gep.ntpc.gov.tw/" target="_blank">
                                <img class="h-7 mt-4" src="../images/si3.svg">
                            </a>
                            <a href="https://www.culture.ntpc.gov.tw/military_village" target="_blank">
                                <img class="h-7 mt-4" src="../images/si4.svg">
                            </a>
                        </div>
                        <div class="w-1/2 -mt-4">
                            <a href="https://www.tshs.ntpc.gov.tw/" target="_blank">
                                <img class="h-4" src="../images/si5.svg">
                            </a>
                            <a href="https://www.sshm.ntpc.gov.tw/" target="_blank">
                                <img class="h-6 mt-4" src="../images/si6.svg">
                            </a>
                            <a href="https://www.linfamily.ntpc.gov.tw/" target="_blank">
                                <img class="h-6 mt-4" src="../images/si7.svg">
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="right-col flex md:items-center gap-x-36 sm:gap-x-40">
                <div class="h-full w-auto">
                    <h3 class="text-xs font-semibold text-ntpccolor">活動資訊</h3>
                    <router-link active-class="active" :to="{ name: 'Travel' }">
                        <p class=" text-footerlink hover:text-ntpcactive cursor-pointer mt-4">綜覽新北</p>
                    </router-link>
                    <router-link active-class="active" :to="{ name: 'Explore' }">
                        <p class=" text-footerlink hover:text-ntpcactive cursor-pointer mt-1">微觀新北</p>
                    </router-link>
                    <router-link active-class="active" :to="{ name: 'Art' }">
                        <p class=" text-footerlink hover:text-ntpcactive cursor-pointer mt-1">藝探新北</p>
                    </router-link>
                    <router-link active-class="active" :to="{ name: 'Fun' }">
                        <p class=" text-footerlink hover:text-ntpcactive cursor-pointer mt-1">奇趣新北</p>
                    </router-link>
                </div>
                <div class="h-full">
                    <h3 class="text-xs font-semibold text-ntpccolor">全國古蹟日社群</h3>
                    <div class="flex items-center mt-5">
                        <a class="mr-4" href="https://www.facebook.com/e7summer" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.816" height="19.816" viewBox="0 0 19.816 19.816">
                                <path id="Icon_awesome-facebook-square" data-name="Icon awesome-facebook-square" d="M17.693,2.25H2.123A2.123,2.123,0,0,0,0,4.373v15.57a2.123,2.123,0,0,0,2.123,2.123H8.194V15.329H5.407V12.158H8.194V9.741c0-2.749,1.637-4.268,4.143-4.268a16.882,16.882,0,0,1,2.456.214v2.7H13.41A1.586,1.586,0,0,0,11.622,10.1v2.059h3.042l-.487,3.171H11.622v6.737h6.071a2.123,2.123,0,0,0,2.123-2.123V4.373A2.123,2.123,0,0,0,17.693,2.25Z" transform="translate(0 -2.25)" fill="#603813"/>
                            </svg>
                        </a>
                        <a class="mr-4" href="https://www.instagram.com/newtaipei_culture/?hl=es" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.82" height="19.816" viewBox="0 0 19.82 19.816">
                                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M9.907,7.065a5.081,5.081,0,1,0,5.081,5.081A5.073,5.073,0,0,0,9.907,7.065Zm0,8.384a3.3,3.3,0,1,1,3.3-3.3A3.309,3.309,0,0,1,9.907,15.449Zm6.473-8.591A1.185,1.185,0,1,1,15.2,5.672,1.182,1.182,0,0,1,16.381,6.857Zm3.365,1.2a5.864,5.864,0,0,0-1.6-4.152,5.9,5.9,0,0,0-4.152-1.6c-1.636-.093-6.54-.093-8.176,0A5.894,5.894,0,0,0,1.665,3.9a5.884,5.884,0,0,0-1.6,4.152c-.093,1.636-.093,6.54,0,8.176a5.864,5.864,0,0,0,1.6,4.152,5.91,5.91,0,0,0,4.152,1.6c1.636.093,6.54.093,8.176,0a5.864,5.864,0,0,0,4.152-1.6,5.9,5.9,0,0,0,1.6-4.152c.093-1.636.093-6.535,0-8.171Zm-2.114,9.927a3.344,3.344,0,0,1-1.884,1.884c-1.3.517-4.4.4-5.841.4s-4.541.115-5.841-.4a3.344,3.344,0,0,1-1.884-1.884c-.517-1.3-.4-4.4-.4-5.841S1.669,7.6,2.182,6.3A3.344,3.344,0,0,1,4.066,4.421c1.3-.517,4.4-.4,5.841-.4s4.541-.115,5.841.4A3.344,3.344,0,0,1,17.632,6.3c.517,1.3.4,4.4.4,5.841S18.149,16.687,17.632,17.987Z" transform="translate(0.005 -2.238)" fill="#603813"/>
                            </svg>
                        </a>
                        <a href="https://www.youtube.com/user/ntpcculture" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.87" height="16.081" viewBox="0 0 22.87 16.081">
                                <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M23.442,7.016A2.874,2.874,0,0,0,21.42,4.981C19.637,4.5,12.485,4.5,12.485,4.5s-7.152,0-8.935.481A2.874,2.874,0,0,0,1.528,7.016a30.145,30.145,0,0,0-.478,5.54,30.145,30.145,0,0,0,.478,5.54,2.831,2.831,0,0,0,2.022,2c1.784.481,8.935.481,8.935.481s7.152,0,8.935-.481a2.831,2.831,0,0,0,2.022-2,30.145,30.145,0,0,0,.478-5.54A30.146,30.146,0,0,0,23.442,7.016Zm-13.3,8.941v-6.8l5.977,3.4-5.977,3.4Z" transform="translate(-1.05 -4.5)" fill="#603813"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="border-footerlink opacity-30">
    <p class="text-xs text-ntpcactive text-center my-4">© 2021.All Rights Reserved. 2021 新北市政府文化局</p>
</footer>
</template>

<script>
export default {}
</script>