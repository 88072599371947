<template>
<div class="explore max-w-screen-2xl flex justify-center mx-auto">
    <div class="max-w-screen-lg mb-20">
        <h1 class="pt-28 md:pt-44 text-center text-ntpccolor text-2xl md:text-3xl font-semibold">
            雲端影遊
        </h1>
        <p class="w-11/12 md:w-2/3 mx-auto pt-6">
            淡水古蹟博物館拍攝系列導覽影片，邀請您以全新視角瀏覽淡水區古蹟；市定古蹟金瓜石神社、金瓜石太子賓館正著手進行修復工程，讓我們從黃金博物館過去的影像紀錄回顧其風采！
        </p>
        <div class="px-4 md:px-12">
            <div class="video mt-10 overflow-hidden cursor-pointer">
                <div class="relative group">
                    <a href="https://youtu.be/Img5XrpEv34" target="_blank" rel="noopener noreferrer">
                        <PlayIcon class="opacity-80 h-20 z-20 cursor-pointer text-white group-hover:text-ntpcactive absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                        <span class="z-10 absolute bg-black opacity-30 w-full h-470"></span>
                    </a>
                    <img class="w-full object-cover h-470 overflow-hidden " src="../images/vr/vr1.jpg" alt="">
                </div>
            </div>
            <h2 class="pt-6 text-ntpccolor text-lg font-semibold">魅力淡水古蹟 — 日式建築篇</h2>
            <p class=" text-justify pt-2">
                還不能⾶出國，不妨來淡⽔古蹟博物館感受濃濃⽇本⾵情。以紅檜木搭建的多⽥榮吉故居，是全台第一間擁有自來水設備的民宅，外牆的雨淋板、屋頂黑瓦及屋內的平竿緣天花板保存完好；警官宿舍則為當時新北市唯一的高階警官宿舍，富含相當多豪華元素的日式老屋，打卡拍照絕對讚數爆表！
            </p>
        </div>

        <!------ for in ------>
        <div class="px-4 md:px-12 pt-24 flex justify-center mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-20 w-full">
                <div v-for="(spot, index) in Spots" :key="index" class="overflow-hidden">
                    <div class="relative group cursor-pointer">
                        <a :href="spot.link" target="_blank" rel="noopener noreferrer">
                            <PlayIcon class="opacity-80 h-20 z-20 cursor-pointer text-white group-hover:text-ntpcactive absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" />
                            <span class="z-10 absolute bg-black opacity-20 w-full h-250"></span>
                        </a>
                        <img :src="spot.img" alt="" class="overflow-hidden object-cover h-250 w-full">
                    </div>
                    <h2 class="pt-6 text-ntpccolor text-lg font-semibold">{{ spot.title }}</h2>
                    <p class="pt-3 text-justify">{{ spot.text }}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { PlayIcon } from '@heroicons/vue/solid'
export default {
    name: 'videoTour',
    components: { PlayIcon },
    setup() {
        const Spots = [
            {
                img: './d_img/vr/vr2.jpg',
                title: '魅力淡水古蹟 — 1884 烽華篇',
                text: '淡水為1884年清法滬尾之役主戰場，並且贏得了勝利。淡水古蹟博物館滬尾礮臺為此而建，並將歷史融入公共藝術裝置，從日常生活中感受當時戰役場景。',
                link: 'https://youtu.be/ROtAOCMsGRk'
            },
            {
                img: './d_img/vr/vr3.jpg',
                title: '魅力淡水古蹟 — 河岸夕陽篇',
                text: '夕陽西下的海景是淡水最美風景，吹著海風駐足在淡水古蹟博物館海關碼頭將觀音山與淡水河的美景一覽無遺。',
                link: 'https://youtu.be/m-RDzRLmXNY'
            },
            {
                img: './d_img/vr/vr4.jpg',
                title: '淡水之美 — 盡收眼底',
                text: '用空拍角度感受淡水最具代表性的文化資產，紅毛城、小白宮、滬尾礮臺及海關碼頭園區壯闊之美。',
                link: 'https://youtu.be/xSY2rJ07C1o'
            },
            {
                img: './d_img/vr/vr5.jpg',
                title: '聆聽淡水之聲',
                text: '淡水獨特的山海河景觀，是歷史與人文薈萃之地，具有多元文化及豐富的古蹟，歡迎來淡水漫遊與探訪。',
                link: 'https://youtu.be/RKGU4813k20'
            },
            {
                img: './d_img/vr/vr6.jpg',
                title: '金瓜石太子賓館盛夏',
                text: '金瓜石太子賓館為日治時期田中礦業株式會社為招待當時原本預定視察金瓜石礦山的皇太子（即後來的昭和天皇），於1922年所興建的臨時行館。 太子賓館建築類型屬傳統日式書院造樣式，融入西式空間，為當時流行的和洋折衷式建築。本影片特邀請堀込憲二教授，介紹金瓜石太子賓館的前世今生與日式建築空間的用途及美學。',
                link: 'https://youtu.be/vri0N8lLfUs'
            },
            {
                img: './d_img/vr/vr7.jpg',
                title: '金瓜石神社與山神祭特展',
                text: '金瓜石神社創建於1898年（明治31年） 3月2日，每年定期舉行的神社祭典，俗稱「山神祭」。金瓜石的礦業公司藉由山神祭活動，祈求金苗多出、採礦平安。山神祭期間全礦山放假，是礦山一年當中難得的假期。比較特別的是，至少自1919年（大正8年）起，礦山的山神祭跟當地漢人的迎媽祖祭典是同一天辦理的。藉由耆老的講述，得以遙想當年熱鬧的歷史情境。',
                link: 'https://youtu.be/Jv-gMTf_8to'
            }
        ]

        return { Spots }
    }
}
</script>