<template>
<div class="explore max-w-screen-2xl flex justify-center mx-auto">
    <div class="max-w-screen-lg mb-20">
        <h1 class="pt-28 md:pt-44 text-center text-ntpccolor text-2xl md:text-3xl font-semibold">
            近距離體驗，文資萬花筒
        </h1>
        <p class="w-11/12 md:w-2/3 mx-auto pt-6 text-footerlink">
            無須踏出家門，即可參加各式各樣的古蹟日互動體驗，無論是線上、實體導覽，或 AR 數位體驗、實境遊戲等，讓你近距離領略文化資產的奧妙。
            <span class=" font-semibold text-ntpcactive">＊實體活動配合新北市防疫政策視情況延後辦理或取消</span>
        </p>
        <div class="px-4 md:px-12">
            <div class="video mt-10 overflow-hidden">
                <img class="w-full object-cover h-400 md:h-470 overflow-hidden " src="../images/explore/ex1.jpg" alt="">
            </div>
            <h2 class=" pt-6 text-ntpccolor text-lg font-semibold">專業帶隊&emsp;文資小旅行</h2>
            <p class=" pt-2">
                新北市全新策劃⽂資⼩旅⾏路線，從故事採集、⽥野調查的再梳理，在地⽂史⼯作者的專業帶領之下，親身探遊歷史場域，聆聽新舊之間的來龍去脈，勾起民眾對在地的想像⼒與回憶，亦能增加對這塊⼟地的認同，並幫助更深度了解⽂資地景。
            </p>
            <hr class=" mt-5 border-gray-300">
            <div class="flex items-center py-2">
                <EyeIcon class="h-6 text-ntpcactive" />
                <router-link to="/tour">
                    <span class="pl-3 text-sm text-ntpccolor hover:text-ntpcactive font-semibold underline">活動點這裡</span>
                </router-link>
            </div>
        </div>

        <!------ for in ------>
        <div class="px-4 md:px-12 pt-24 flex justify-center mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-20 w-full">
                <div v-for="(spot, index) in Spots" :key="index" class="overflow-hidden">
                    <img :src="spot.img" alt="" class="overflow-hidden object-cover h-300 md:h-400 w-full">
                    <h2 class="pt-6 text-ntpccolor text-lg font-semibold" v-html="spot.title"></h2>
                    <p class="pt-3 text-justify">{{ spot.text }}</p>
                    <hr class=" mt-5 border-gray-300">
                    <div class="flex items-center py-2">
                        <EyeIcon class="h-6 text-ntpcactive" />
                        <a :href="spot.link" :target="spot.target" rel="noopener noreferrer">
                            <span class="pl-3 text-sm text-ntpccolor hover:text-ntpcactive font-semibold underline">{{ spot.icontext }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr class="border-gray-300">
<div class=" clear-both max-w-screen-2xl flex justify-center mx-auto mb-40">
    <div class="max-w-screen-lg w-full px-4 md:px-12">
        <h2 class="text-center text-ntpccolor text-2xl md:text-3xl font-semibold mt-20 mb-10">來看更多新北奇蹟</h2>
        <div class="grid grid-cols-1 md:grid-rows-1 md:grid-cols-3 gap-6 w-full">
            <div class="">
                <router-link :to="{ name: 'Travel' }" class="group">
                <img src="../images/travel/travel_home.jpg" alt="" class="overflow-hidden object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">綜覽新北</p>
                        <h2 class=" py-2 text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">360度實境環景六大路線</h2>
                    <p>手指動一動，探索古蹟360度全景影像，6大私房路線推薦！</p>
                </div>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'Art' }" class="group">
                <div class="overflow-hidden h-280">
                    <img src="../images/art/art_mobile.jpg" alt="" class="overflow-hidden bg-cover h-auto w-full">
                </div>
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">藝探新北</p>
                        <h2 class=" py-2 text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">精彩策展古蹟嘉年華</h2>
                    <p>古蹟日重量級展演情報，不可錯過的精彩演出。</p>
                </div>
                </router-link>
            </div>
            <div>
                <router-link :to="{ name: 'Fun' }" class="group">
                <img src="../images/fun/fun_home.jpg" alt="" class="object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">奇趣新北</p>
                        <h2 class=" py-2 text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">逛古蹟抽大獎</h2>
                    <p>有獎徵答、臉書抽獎、闖關小遊戲，精美限量好禮帶回家！</p>
                </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { EyeIcon } from '@heroicons/vue/solid'

export default {
    name: 'Explore',
    components: { EyeIcon },

    setup() {
        const Spots = [
            {
                img: './d_img/explore/ex2.jpg',
                title: '線上虛擬旅行&emsp;在家遊八里',
                text: '十三行博物館於全國古蹟日期間推出「線上虛擬旅行—在家遊八里」，透過八里實景拍攝、「八里聲音漫遊」聲音劇場及數位博物館等元素，邀請您在家遊八里，線上一覽八里自然景色與人文故事。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.sshm.ntpc.gov.tw/',
                target: '_blank'
            },
            {
                img: './d_img/explore/ex3.jpg',
                title: '雲端影遊',
                text: '淡水古蹟博物館拍攝系列導覽影片，邀請您以全新視角瀏覽淡水區古蹟；市定古蹟金瓜石神社、金瓜石太子賓館正著手進行修復工程，讓我們從黃金博物館過去的影像紀錄回顧其風采！',
                icon: 'VideoCameraIcon',
                icontext: '活動點這裡',
                link: '/video',
                target: ''
            },
            {
                img: './d_img/explore/ex4.jpg',
                title: '林家花園主題網遊',
                text: '林家花園全新企劃，打造有別於傳統導覽，搭配精緻影片、互動小遊戲與在地小故事，主打3條主題路線「情字這條路」、「吃貨一條街」和「讀歲月枋橋」，邀請您從古早的愛情產業鏈、生活飲食文化等面向沉浸枋橋歷史演變脈絡。9/11起，跟著我們提示的線索一起「網遊」體驗吧！',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://banqiaotrip.tw',
                target: '_blank'
            },
            {
                img: './d_img/explore/ex5.jpg',
                title: '眷村尋寶探險',
                text: '「空軍三重一村」為北臺灣僅存的空軍防砲部隊眷村，擁有日治時期構築的防砲陣地及掩體甬道，後者更是以前村民探險玩耍的秘密基地。為了讓觀者體驗多變的眷村風情，空軍三重一村特別推出AR數位體驗，除能對照今昔場景，還能觀賞轟炸情景與眷舍分級、防洪樓等動畫，歡迎您至園區透過手機APP在眷村尋寶探險！',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://bit.ly/3yrNc9V',
                target: '_blank'
            },
            {
                img: './d_img/explore/ex6.jpg',
                title: '實境遊戲《遺落夢境》',
                text: '新北市在推動土城醫院的興建，整理遷葬土城第一公墓時，沉寂已久的斬龍山遺址再度出土。為了不干擾地底文化層的情況下進行現地保存，該遺址已規劃為公園，並於2019年公告為市定考古遺址。2021年，新北市文化局X聚樂邦推出實境解謎遊戲《遺落夢境》，想要到遺址公園親自尋找破關線索，或在家不出門也想體驗至公園現場沉浸實境解謎的樂趣，2種方案任君選擇。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.clubon.space/pages/cls-game?utm_source=fb&utm_medium=post&utm_campaign=cls_start',
                target: '_blank'
            }
        ]

        return { Spots }
    }
}
</script>