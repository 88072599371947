<template>
<div class="carousel  max-w-full overflow-hidden" >
    <div v-for="(slide, index) in carouselSlides" :key="index" class="flex justify-center mx-auto bg-ntpcbg">
        <div :currentSlide="currentSlide" class="slide overflow-hidden">
            <div v-show="currentSlide === index + 1" class="slide-info bg-ntpcbg relative">
                <div class="absolute bottom-8 sm:-bottom-3 left-1/2 right-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center ">
                    <div class="flex items-center gap-2">
                        <a :href="slide.url" target="_blank">
                            <div class="h-24 w-24 sm:h-28 sm:w-28">
                                <img :src="slide.map" class="cursor-pointer">
                            </div>
                        </a>
                        <a :href="slide.vrurl">
                            <div class="h-24 w-24 sm:h-28 sm:w-28">
                                <img :src="slide.vr" class="cursor-pointer">
                            </div>
                        </a>
                    </div>
                </div>
                <img class="md:block hidden mx-auto object-cover md:h-600 lg:h-800 pt-28 pb-4" :src="slide.img">
                <img class="md:hidden block mx-auto object-cover pt-20 pb-4" :src="slide.img_s">
            </div>
        </div>
    </div>
    <div class="pagination py-8 w-full flex justify-center">
        <div class="flex flex-wrap content-evenly justify-center gap-x-8 gap-y-4 px-4">
            <p @click="goToSlide(index=0)" :class="{ active: currentSlide === 1 }" class="cursor-pointer text-ntpccolor text-sm">
                淡水區
            </p>
            <p @click="goToSlide(index=1)" :class="{ active: currentSlide === 2 }" class="cursor-pointer text-ntpccolor text-sm">
                中和、板橋區
            </p>
            <p @click="goToSlide(index=2)" :class="{ active: currentSlide === 3 }" class="cursor-pointer text-ntpccolor text-sm">
                新店區
            </p>
            <p @click="goToSlide(index=3)" :class="{ active: currentSlide === 4 }" class="cursor-pointer text-ntpccolor text-sm">
                五股、蘆洲區
            </p>
            <p @click="goToSlide(index=4)" :class="{ active: currentSlide === 5 }" class="cursor-pointer text-ntpccolor text-sm">
                三重、新莊區
            </p>
            <p @click="goToSlide(index=5)" :class="{ active: currentSlide === 6 }" class="cursor-pointer text-ntpccolor text-sm">
                金山、瑞芳、貢寮區
            </p>
        </div>
    </div>
</div>


</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    name: 'travelBanner',

    setup() {
        const carouselSlides = [
            {
                id: 1,
                img:'./d_img/travel/travel1.png',
                img_s:'./d_img/travel/travel1s.png',
                url: 'https://www.google.com/maps/d/u/0/viewer?mid=1iGsydT-70HaQFofuYaxw-W3nL-hwF40R&ll=25.056746143922492%2C121.57143604589842&z=11',
                map: './d_img/travel/travel_map.png',
                vrurl: 'https://ntpc.lihow.tw/tamsui/index.htm',
                vr: './d_img/travel/travel_360.png'
            },
			{
                id: 2,
                img:'./d_img/travel/travel2.png',
                img_s:'./d_img/travel/travel2s.png',
                url: 'https://www.google.com/maps/d/u/0/viewer?mid=1iGsydT-70HaQFofuYaxw-W3nL-hwF40R&ll=25.056746143922492%2C121.57143604589842&z=11',
                map: './d_img/travel/travel_map.png',
                vrurl: 'https://ntpc.lihow.tw/zhonghe-banqiao/index.htm',
                vr: './d_img/travel/travel_360.png'
            },
			{
                id: 3,
                img:'./d_img/travel/travel3.png',
                img_s:'./d_img/travel/travel3s.png',
                url: 'https://www.google.com/maps/d/u/0/viewer?mid=1iGsydT-70HaQFofuYaxw-W3nL-hwF40R&ll=25.056746143922492%2C121.57143604589842&z=11',
                map: './d_img/travel/travel_map.png',
                vrurl: 'https://ntpc.lihow.tw/xindian/index.htm',
                vr: './d_img/travel/travel_360.png'
            },
            {
                id: 4,
                img:'./d_img/travel/travel4.png',
                img_s:'./d_img/travel/travel4s.png',
                url: 'https://www.google.com/maps/d/u/0/viewer?mid=1iGsydT-70HaQFofuYaxw-W3nL-hwF40R&ll=25.056746143922492%2C121.57143604589842&z=11',
                map: './d_img/travel/travel_map.png',
                vrurl: 'https://ntpc.lihow.tw/wugu-luzhou/index.htm',
                vr: './d_img/travel/travel_360.png'
            },
            {
                id: 5,
                img:'./d_img/travel/travel5.png',
                img_s:'./d_img/travel/travel5s.png',
                url: 'https://www.google.com/maps/d/u/0/viewer?mid=1iGsydT-70HaQFofuYaxw-W3nL-hwF40R&ll=25.056746143922492%2C121.57143604589842&z=11',
                map: './d_img/travel/travel_map.png',
                vrurl: 'https://ntpc.lihow.tw/xinzhuang-sanchong/index.htm',
                vr: './d_img/travel/travel_360.png'
            },
            {
                id: 6,
                img:'./d_img/travel/travel6.png',
                img_s:'./d_img/travel/travel6s.png',
                url: 'https://www.google.com/maps/d/u/0/viewer?mid=1iGsydT-70HaQFofuYaxw-W3nL-hwF40R&ll=25.056746143922492%2C121.57143604589842&z=11',
                map: './d_img/travel/travel_map.png',
                vrurl: 'https://ntpc.lihow.tw/ruifang-jinshan/index.htm',
                vr: './d_img/travel/travel_360.png'
            }
        ]

        const currentSlide = ref(1)
        const getSlideCount = ref(null)
        const autuPlayEnable = ref(true)
        const timeoutDuration = ref(10000)

        // next slide
        const nextSlide = () => {
            if (currentSlide.value === getSlideCount.value) {
                currentSlide.value = 1
                return
            }
            currentSlide.value += 1
        }

        const goToSlide = (index) => {
            currentSlide.value = index + 1
        }

        // autoplay
        const autoPlay = () => {
            setInterval(() => {
                nextSlide()
            }, timeoutDuration.value)
        }

        if (autuPlayEnable.value) {
            autoPlay()
        }

        onMounted(() => {
            getSlideCount.value = document.querySelectorAll(".slide").length
            // console.log(getSlideCount.value)
        })

        return { currentSlide, carouselSlides, nextSlide, getSlideCount, goToSlide }
    }
}
</script>