/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import Travel from "../views/Travel.vue"
import Explore from "../views/Explore.vue"
import Art from "../views/Art.vue"
import Fun from "../views/Fun.vue"
import Tour from "../views/Tour.vue"
import videoTour from "../views/videoTour.vue"

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    {
        path: "/travel",
        name: "Travel",
        component: Travel
    },
    {
        path: "/explore",
        name: "Explore",
        component: Explore
    },
    {
        path: "/art",
        name: "Art",
        component: Art
    },
    {
        path: "/fun",
        name: "Fun",
        component: Fun
    },
    {
        path: "/tour",
        name: "Tour",
        component: Tour
    },
    {
        path: "/video",
        name: "videoTour",
        component: videoTour
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

export default router
