<template>
<Banner />

<!-- home content -->
<div class="max-w-screen-2xl mx-auto h-full">
	<div class="about pt-8 px-4 md:px-10 bg-white">
		<div class="left">
			<h1 class="text-5xl font-etitle text-ntpcactive">About</h1>
		</div>
		<div class="right max-w-screen-lg mx-auto">
			<h1 class="pt-10 text-center text-ntpccolor text-xl font-semibold">
				2021年全國古蹟日<br>
				新北奇蹟 ‧ 科技走讀
			</h1>
			<div class="md:w-5/6 mx-auto pt-4 text-justify">
				<p>
					為響應全國古蹟日，本市特將年度主题訂為「文資萬花筒．異想新北」，森然羅列在地文化資產之人文歷史特色，並區分「綜覽新北」、「微觀新北」、「藝探新北」、「奇趣新北」4大主题。因應疫情之非日常狀態，以「新北奇蹟．科技走讀」為主軸，主推線上虚擬實境文資小旅行，以3D環景導覽文資地景，輔以多元導覽體驗，邀請您探索在地文化資產。
				</p>
			</div>
			<div class="video aspect-w-16 aspect-h-9 md:w-11/12 mx-auto mt-10  md:px-10 flex justify-center">
				<iframe src="https://www.youtube.com/embed/CrWD5p_VdRA?start=1" title="新北奇蹟" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>
		</div>
		<hr class="mt-20 border-gray-300">
	</div>
	<div class="travel mx-auto pt-8 px-4  md:px-10 bg-white">
		<div class="left">
			<h1 class="text-5xl font-etitle text-ntpcactive">Travel</h1>
		</div>
		<div class="right max-w-screen-lg mx-auto">
			<h1 class="pt-10 text-center text-ntpccolor text-xl font-semibold">
				綜覽新北
			</h1>
			<div class="mx-auto pt-4 text-center">
				<p>
					只要手指動一動，就能盡情探索古蹟景點最美的360度全景影像，6大私房路線真心推薦！
				</p>
			</div>
			<div class="mt-10 md:w-11/12 mx-auto block">
				<div class="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
				<div v-for="(spot, index) in Travels" :key="index" class="group">
                    <div class="relative">
                        <a :href="spot.link" :target="spot.target">
                            <img class="z-10 cursor-pointer stroke-current h-6 md:h-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src="../images/travel/360.svg">
							<h2 class="z-10 break-normal text-white text-xs md:text-sm group-hover:text-ntpcactive absolute bottom-3 md:bottom-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2">{{ spot.title }}</h2>
							<span class="absolute bg-black opacity-20 hover:opacity-50 w-full h-full"></span>
							<img :src="spot.img" class="pt-0 h-32 w-full sm:h-40 md:h-56 object-cover">
						</a>
					</div>
				</div>
			</div>
			</div>
		</div>
		<hr class="mt-20 border-gray-300">
	</div>
	<div class="explore mx-auto pt-8 px-4  md:px-10 bg-white">
		<div class="left">
			<h1 class="text-5xl font-etitle text-ntpcactive">Explore</h1>
		</div>
		<div class="right max-w-screen-lg mx-auto">
			<h1 class="pt-10 text-center text-ntpccolor text-xl font-semibold items-center">
				微觀新北
			</h1>
			<div class="mx-auto pt-4 px-4 text-center md:flex md:justify-center md:items-center">
				<p>
					集結古蹟日各種線上互動體驗，還有專業帶隊深度旅行，帶你近距離接觸文化資產的奧妙！
				</p>
				<div class="flex items-center justify-center">
					<EyeIcon class="mr-2 h-6 text-ntpcactive" />
					<router-link :to="{ name: 'Explore' }">
						<span class="text-ntpccolor hover:text-ntpcactive font-semibold cursor-pointer">看更多活動</span>
					</router-link>
				</div>
			</div>
			<div class="md:w-11/12 mt-10 bg-ntpccolor mx-auto overflow-hidden">
				<router-link :to="{ name: 'Explore' }">
					<img class="w-full object-cover h-300 md:h-470 overflow-hidden " src="@/images/explore/ex_home.jpg" alt="">
				</router-link>
			</div>
		</div>
		<hr class="mt-20 border-gray-300">
	</div>
	<div class="art mx-auto pt-8 px-4  md:px-10 bg-white">
		<div class="left">
			<h1 class="text-5xl font-etitle text-ntpcactive">Art</h1>
		</div>
		<div class="right max-w-screen-lg mx-auto">
			<h1 class="pt-10 text-center text-ntpccolor text-xl font-semibold">
				藝探新北
			</h1>
			<div class="mx-auto pt-4 px-4 text-center md:flex md:justify-center md:items-center">
				<p>
					各地重量級展演大咖登場，透過精彩主題策展，將古蹟日變成一場動靜皆宜的嘉年華會。
				</p>
				<div class="flex items-center justify-center">
					<EyeIcon class="mr-2 h-6 text-ntpcactive" />
					<router-link :to="{ name: 'Art' }">
						<span class="text-ntpccolor hover:text-ntpcactive font-semibold cursor-pointer">看更多活動</span>
					</router-link>
				</div>
			</div>
			<div class="md:w-11/12 mt-10 bg-ntpccolor mx-auto block overflow-hidden">
			<router-link :to="{ name: 'Art' }">
				<img class="w-full  object-cover h-300 md:h-470 overflow-hidden" src="@/images/art/art1.png" alt="">
			</router-link>
			</div>
		</div>
		<hr class="mt-20 border-gray-300">
	</div>
	<div class="fun mx-auto pt-8 px-4  md:px-10 bg-white">
		<div class="left">
			<h1 class="text-5xl font-etitle text-ntpcactive">Fun</h1>
		</div>
		<div class="right max-w-screen-lg mx-auto">
			<h1 class="pt-10 text-center text-ntpccolor text-xl font-semibold">
				奇趣新北
			</h1>
			<div class="mx-auto pt-4 px-4 text-center md:flex md:justify-center md:items-center">
				<p>
					逛古蹟抽大獎！來玩有獎徵答、臉書抽獎或闖關小遊戲，精美限量好禮讓你帶回家！
				</p>
				<div class="flex items-center justify-center">
					<SpeakerphoneIcon class="mr-2 h-6 text-ntpcactive" />
					<router-link :to="{ name: 'Fun' }">
						<span class="text-ntpccolor hover:text-ntpcactive font-semibold cursor-pointer">參加活動GO！</span>
					</router-link>
				</div>
			</div>
			<div class="md:w-11/12 relative mt-10 bg-ntpccolor mx-auto block overflow-hidden">
				<span class="absolute bottom-1 left-1 text-xs text-white">力麗慈善事業基金會收錄</span>
				<router-link :to="{ name: 'Fun' }">
					<img class="w-full object-cover h-300 md:h-470 overflow-hidden" src="@/images/fun/fun_home.jpg" alt="力麗慈善事業基金會收錄">
				</router-link>
			</div>
		</div>
		<hr class="mt-20 border-white">
	</div>
</div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import { EyeIcon, SpeakerphoneIcon } from '@heroicons/vue/solid'

export default {
	name: 'Home',
	components: { Banner, EyeIcon, SpeakerphoneIcon },

	setup() {
        const Travels = [
            {
                img: './d_img/travel/travel_home.jpg',
                title: '淡水區',
                link: 'https://ntpc.lihow.tw/tamsui/index.htm',
            },
            {
                img: './d_img/travel/travel_home2_2.jpg',
                title: '中和、板橋區',
                link: 'https://ntpc.lihow.tw/zhonghe-banqiao/index.htm',
            },
            {
                img: './d_img/travel/travel_home1.jpg',
                title: '新店區',
                link: 'https://ntpc.lihow.tw/xindian/index.htm',
            },
            {
                img: './d_img/travel/travel_home2.jpg',
                title: '五股、蘆洲區',
                link: 'https://ntpc.lihow.tw/wugu-luzhou/index.htm',
            },
            {
                img: './d_img/travel/travel_home5.jpg',
                title: '三重、新莊區',
                link: 'https://ntpc.lihow.tw/xinzhuang-sanchong/index.htm',
            },
			{
                img: './d_img/travel/travel_home6.jpg',
                title: '金山、瑞芳、貢寮區',
                link: 'https://ntpc.lihow.tw/ruifang-jinshan/index.htm',
            }
        ]

        return { Travels }
    }
}
</script>
