<template>
<travelBanner />

<!-- travel content -->
<div class="travel clear-both max-w-screen-2xl mx-auto mb-40">
    <hr class=" mt-4 border-gray-300">
	<div class="pt-8 px-5 bg-white">
		<div class="left">
			<h1 class="text-5xl font-etitle text-ntpcactive">Travel</h1>
		</div>
		<div class="max-w-screen-lg mx-auto">
			<div class="md:px-12 md:grid md:grid-cols-1">
				<div v-for="(spot, index) in Travels" :key="index" class="pt-20 md:flex md:items-center gap-12">
                    <div class="md:w-1/2 relative">
                        <a :href="spot.link" :target="spot.target">
                            <img class="z-10 cursor-pointer stroke-current hover:text-ntpcactive absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src="../images/travel/360.svg">
                            <span class="absolute bg-black opacity-20 hover:opacity-50 w-full h-full"></span>
                            <img :src="spot.img" class="pt-0 h-300 object-cover">
                        </a>
					</div>
					<div class="mt-8 md:mt-0 md:w-1/2">
						<h2 class="text-ntpccolor text-lg font-semibold">{{ spot.title }}</h2>
						<p class="py-3 text-justify">{{ spot.text }}</p>
						<hr class=" mt-4 border-gray-300">
						<div class="flex items-center py-1">
							<EyeIcon class="h-6 text-ntpcactive" />
							<a :href="spot.link" :target="spot.target" rel="noopener noreferrer">
								<span class="pl-3 text-sm text-ntpccolor hover:text-ntpcactive font-semibold underline">{{ spot.icontext }}</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import travelBanner from '@/components/travelBanner.vue'
import { EyeIcon } from '@heroicons/vue/solid'

export default {
    name: 'Travel',
    components: { travelBanner, EyeIcon },

	setup() {
        const Travels = [
            {
                img: './d_img/travel/travel_home.jpg',
                title: '路線一：淡水區',
                text: '以淡水古蹟園區串聯的古蹟點及其周邊文資，包括淡水紅毛城、滬尾礮臺、前清淡水關稅務司官邸、淡水海關碼頭、淡水街長多榮吉故居、公司田溪程氏古厝、淡水木下靜涯舊居、淡水日本警官宿舍，以及淡水區私人古蹟例如鄞山寺、淡水龍山寺、淡水禮拜堂、滬尾偕醫館等12處精彩景點，不論是軍事、防禦性建築，或是充滿日式風情的老屋宅邸，沿路盡覽曾是臺灣最大國際貿易港口的淡水風光。',
                icon: 'EyeIcon',
                icontext: '景點12處',
                link: 'https://ntpc.lihow.tw/tamsui/index.htm',
                target: ''
            },
            {
                img: './d_img/travel/travel_home2_2.jpg',
                title: '路線二：中和、板橋區',
                text: '歷經兩年修復的日治時期瑞穗配水池，加上具時代建築、流派、技術之意義的圓通禪寺，乃傳承自大乘佛教曹洞宗，為臺灣早期專收女性之佛寺，一次走逛中和古蹟廊帶二大重點；以板橋林本源園邸為歷史核心的古蹟建築，祭祀戰死的武師、壯勇的迪毅堂，既是學堂又是廟宇的大觀義學，以及浮洲劉氏古厝與茉莉花香的故事，都在這裡細說從頭。',
                icon: 'VideoCameraIcon',
                icontext: '景點6處',
                link: 'https://ntpc.lihow.tw/zhonghe-banqiao/index.htm',
                target: ''
            },
            {
                img: './d_img/travel/travel_home1.jpg',
                title: '路線三：新店區',
                text: '灌溉、發電、水利，是新店古蹟三大亮點，粗坑發電廠是台電目前運轉中最古老的發電廠；瑠公圳為清代三大水圳之一，「瑠公圳引水石硿」屬大坪林圳一支，引水路線行經開天宮至新店後街，直接在山崖岩石層上以人工穿鑿作為取水口，在當時實屬不易。新店渡早年是新店唯一進入灣潭、直潭、塗潭以及屈尺等山區的交通樞紐，現已成為假日休閒的觀光景點。',
                icon: 'EyeIcon',
                icontext: '景點4處',
                link: 'https://ntpc.lihow.tw/xindian/index.htm',
                target: ''
            },
            {
                img: './d_img/travel/travel_home2.jpg',
                title: '路線四：五股、蘆洲區',
                text: '在地聞人吳愚家族的起家厝—守讓堂，是新北市首次文化資產拆遷異地重組的成功案例；超過兩百年的西雲古寺，廟身為簡樸單殿式帶左右護龍的三合院格局，為五股區珍貴古蹟；另蘆洲保和宮與蘆洲李宅，一同見證蘆洲李氏家族在本地的開拓歷程，更是過去和尚洲（蘆洲舊稱）由沙洲逐步開墾為農田、農村，並因交通發展，逐漸轉為商業市集，為蘆洲核心地區土地利用模式及街區紋理轉變之重要見證。',
                icon: 'EyeIcon',
                icontext: '景點4處',
                link: 'https://ntpc.lihow.tw/wugu-luzhou/index.htm',
                target: ''
            },
            {
                img: './d_img/travel/travel_home5.jpg',
                title: '路線五：三重、新莊區',
                text: '擁有三百多年歷史的新莊老街，是臺灣發展史的縮影，不但古蹟廟宇、百年傳統老店林立，走逛巷弄漫遊，充滿思古之幽情，包括國定古蹟廣福宮、市定古蹟武聖廟、文昌祠及慈祐宮，都是必遊重點；鄰近的三重先嗇宮，擁有超過250年的歷史，是境內最古老、最重要的信仰中心；空軍三重一村則是目前北部唯一完整保留的防砲眷村，有日治時期的高射砲陣地，以及運送彈藥的地下甬道遺跡。',
                icon: 'EyeIcon',
                icontext: '景點6處',
                link: 'https://ntpc.lihow.tw/xinzhuang-sanchong/index.htm',
                target: ''
            },
			{
                img: './d_img/travel/travel_home6.jpg',
                title: '路線六：金山、瑞芳、貢寮區',
                text: '金包里慈護宮供奉金面媽祖，是當地信仰中心；曾經風華一時的水金九採礦業（水湳洞、金瓜石、九份），留下金瓜石礦業圳道及圳橋、金瓜石礦山事務所所長宿舍、水湳洞選煉廠等遺跡，彰顯人類與自然的深刻交錯；建於日本時代的瑞芳四腳亭砲臺，是新北市少見的砲臺類古蹟；馬崗街11、12號民宅為坐落於臺灣極東海角之石砌建物，早期居民就地取材自附近石頭建屋及防浪之厚堤，反映在地生活條件。',
                icon: 'EyeIcon',
                icontext: '景點6處',
                link: 'https://ntpc.lihow.tw/ruifang-jinshan/index.htm',
                target: ''
            }
        ]

        return { Travels }
    }
}
</script>
