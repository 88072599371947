<template>
<div class="art max-w-screen-2xl flex justify-center mx-auto">
    <div class="max-w-screen-lg mb-20">
        <h1 class="pt-28 md:pt-44 text-center text-ntpccolor text-2xl md:text-3xl font-semibold">
            展演大集合，動靜都好玩
        </h1>
        <p class="w-11/12 md:w-2/3 mx-auto pt-6 text-footerlink">
            疫情趨緩，重量級展演紛紛登場，不管是主題特展、數位結合實體常設展、藝術季、沉浸式劇場或工作坊，精彩展演齊聚一堂，古蹟日變成一場嘉年華會。
        </p>
        <div class=" px-4 md:px-12">
            <div class="hidden md:block  mt-10 overflow-hidden">
                <img class="w-full object-cover h-470 overflow-hidden " src="../images/art/art1.png" alt="">
            </div>
            <div class="video mt-10 overflow-hidden">
                <img class="mobile-banner md:hidden w-full object-cover h-470 overflow-hidden " src="../images/art/art_mobile.jpg" alt="">
            </div>
            <h2 class=" pt-6 text-ntpccolor text-lg font-semibold">馬崗石砌屋建築在地記憶展覽：石至今日</h2>
            <p class=" pt-2">
                「馬崗石砌屋建築在地記憶展覽–石至今日」聚焦於「馬崗漁村」之在地特色，藉由展出在地風光之VR環景影片、空拍影片、海女與石砌屋文化等內容，建構關於馬崗漁村之在地歷史人文記憶。馬崗漁村因其特殊地緣位置及歷史條件的特殊性，使其較能維持傳統海洋漁業，促使「海女」職業之誕生，此外，在地居民更就地取材，建造石砌居室，形成臺灣少有之民居形態，亦反映出居民因應氣候，運用在地生活條件結合環境特色，見證當時之石工與建築技術。
            </p>
            <hr class=" mt-5 border-gray-300">
            <div class="flex items-center py-2">
                <EyeIcon class="h-6 text-ntpcactive" />
                    <a href="https://mg.ntua.edu.tw/" target="_blank">
                        <span class="pl-3 text-sm cursor-pointer text-ntpccolor hover:text-ntpcactive font-semibold underline">活動點這裡</span>
                    </a>
            </div>
        </div>

        <!------ for in ------>
        <div class="px-4 md:px-12 pt-24 flex justify-center mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-20 w-full">
                <div v-for="(art, index) in Arts" :key="index" class="overflow-hidden">
                    <img :src="art.img" alt="" class="overflow-hidden object-cover h-300 md:h-400 w-full">
                    <div class="relative md:h-400">
                        <h2 class="pt-6 text-ntpccolor text-lg font-semibold" v-html="art.title"></h2>
                        <p v-html="art.text" class="pt-3 text-justify"></p>
                        <div class="md:absolute bottom-0 w-full">
                            <hr class=" mt-5 border-gray-300">
                            <div class="flex items-center py-2">
                                <EyeIcon class="h-6 text-ntpcactive" />
                                <a :href="art.link" target="_blank" rel="noopener noreferrer">
                                    <span class="pl-3 text-sm cursor-pointer text-ntpccolor hover:text-ntpcactive font-semibold underline">{{ art.icontext }}</span>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<hr class="border-gray-300">
<div class=" clear-both max-w-screen-2xl flex justify-center mx-auto mb-40">
    <div class="max-w-screen-lg w-full px-4 md:px-12">
        <h2 class="text-center text-ntpccolor text-2xl md:text-3xl font-semibold mt-20 mb-10">來看更多新北奇蹟</h2>
        <div class="grid grid-cols-1 md:grid-rows-1 md:grid-cols-3 gap-6 w-full">
            <div class="">
                <router-link :to="{ name: 'Travel' }" class="group">
                <img src="../images/travel/travel_home.jpg" alt="" class="overflow-hidden object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">綜覽新北</p>
                        <h2 class=" py-2 cursor-pointer text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">360度實境環景六大路線</h2>
                    <p>手指動一動，探索古蹟360度全景影像，6大私房路線推薦！</p>
                </div>
                </router-link>
            </div>
            <div class="">
                <router-link :to="{ name: 'Explore' }" class="group">
                <img src="../images/explore/ex_home.jpg" alt="" class="overflow-hidden object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">微觀新北</p>
                        <h2 class=" py-2 cursor-pointer text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">近距走讀文資萬花筒</h2>
                    <p>線上互動體驗，專業帶隊深度旅行，近距離接觸古蹟的奧妙！</p>
                </div>
                </router-link>
            </div>
            <div class="">
                <router-link :to="{ name: 'Fun' }" class="group">
                <img src="../images/fun/fun_home.jpg" alt="" class="overflow-hidden object-cover h-280 w-full">
                <div class=" mt-6 border-l-4 border-ntpcactive px-4">
                    <p class="text-sm text-ntpcactive">奇趣新北</p>
                        <h2 class=" py-2 cursor-pointer text-ntpccolor group-hover:text-ntpcactive text-lg font-semibold">逛古蹟抽大獎</h2>
                    <p>有獎徵答、臉書抽獎、闖關小遊戲，精美限量好禮帶回家！</p>
                </div>
                </router-link>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { EyeIcon } from '@heroicons/vue/solid'
export default {
    name: 'Art',
    components: { EyeIcon },
    setup() {
        const Arts = [
            {
                img: './d_img/art/art2.jpg',
                title: '十三行數位博物館',
                text: '因應社會數位發展趨勢，十三行博物館應用數位技術與網絡，打破博物館實體空間限制，升級博物館典藏、展示、教育及公共服務等功能，讓博物館與觀眾互動零距離。透過十三行數位博物館，您可以輕鬆在家中以擬真720度環景視角感受多元主題特展魅力及檢視重點文物，並體驗線上互動，歡迎密切關注官網及臉書粉絲專頁訊息。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://sshm.vm.ntpc.gov.tw/'
            },
            {
                img: './d_img/art/art3.jpg',
                title: '日常之歌—四連棟聲音再現常設展',
                text: '金瓜石四連棟為傳統日式宿舍，是1930年代由日本鑛業株式會社所興建。「四連棟聲音再現」常設展示，於懷舊宿舍空間設置礦山的環境聲音，讓民眾身歷其境體驗昔日的生活場景。另同步規劃線上3D導覽，提供民眾在疫情之下線上體驗礦業常民生活即景。<span class="font-semibold text-ntpcactive">＊防疫期間配合館舍開放時間</span>',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.gep.ntpc.gov.tw/xceventsnews/cont?xsmsid=0G266496700270752519&sid=0L254369958887614370'
            },
            {
                img: './d_img/art/art4.jpg',
                title: '黃金博物館&emsp;2021礦山藝術季',
                text: '黃金博物館即日起至9/17舉辦「2021礦山藝術季」，邀請國際知名藝術家王文智，與林承勳、葉郁鑫、曾熙凱、陳昱榮、王佳蘭、林書豪、朱婉萍及黃仲辰等8位藝術家以線上駐村的方式，創作1件實體作品及8件共創作品。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.mineartfestival.com/'
            },
            {
                img: './d_img/art/art5.jpg',
                title: '淡水紅毛城&emsp;一座歷史劇場',
                text: '今日的紅毛城是由荷蘭人在1644年重新建造，歷經不同時代的歲月流轉，共有九個政權在此駐守、辦公或代管，屹立將近400年的堡壘，重新設計承載故事的展示，向世人分享它的故事篇章。<span class="font-semibold text-ntpcactive">＊防疫期間配合館舍開放時間</span>',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.tshs.ntpc.gov.tw/xmdoc/cont?xsmsid=0G245655848901208901&sid=0I092411356795852419'
            },
            {
                img: './d_img/art/art6.jpg',
                title: '板橋林家花園&emsp;沉浸式劇場《西城故事》',
                text: '何歡劇團9月於板橋林家花園演出《西城故事》沉浸式劇場！改編莎士比亞《羅密歐與朱麗葉》，故事背景源於18世紀中期漳泉械鬥，以板橋、林家故事以及所處大時代為背景，帶出皆大歡喜的結局。融合林家花園故事並以現代話劇模式演出，在園內各個場域進行沉浸式劇場，活潑演譯漳泉械鬥歷史，讓民眾深入其境，在沉浸古蹟風情之餘，更能了解歷史人文故事，演出時更將邀請觀眾一同參與互動，傳遞園邸故事歷史與人文精神。<span class="font-semibold text-ntpcactive">＊因應疫情延後辦理</span>',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://reurl.cc/L0OyQK'
            },
            {
                img: './d_img/art/art7.jpg',
                title: '空軍三重一村&emsp;俯瞰-空照圖特展',
                text: '許多防砲陣地隨都市發展，幾乎消失於現代街景中，僅存空軍三重一村可清楚辨認遺跡。空軍三重一村即日起至111/6/30止，於園區舉辦「俯瞰-空照圖特展」，邀請中央研究院人社中心GIS地理資訊科學研究專題中心與國防部空軍司令部共同策劃，大量運用中研院GIS中心典藏日治時代至今的地理圖資，詳細介紹戰後空軍偵照單位的底片沖印判讀流程，並搭配行政院農委會林務局農林航空測量提供最新的雙北空拍照，歡迎大、小朋友可在此次特展中一起學歷史、聽故事、學歷史、玩科普。<span class="font-semibold text-ntpcactive">＊防疫期間配合館舍開放時間</span>',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://bit.ly/38oUJf6'
            },
            {
                img: './d_img/art/art8.jpg',
                title: '五股守讓堂建築之美&emsp;剪黏工作坊',
                text: '以異地重組方式重現的五股守讓堂，竣工後成為連結市民藝文生活與在地學習的公共空間，設有留餘廳、 稻香居 、 春臨館、 秀水軒及藝遊工坊等常設展間，藏藝閣保留原始建材，展現修復過程面貌。古蹟日期間特別邀請剪黏藝術家，帶領民眾認識何為剪粘及其在建築上的應用，讓我們動動手、學習這門老技藝吧~',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.culture.ntpc.gov.tw/wgsrh'
            },
            {
                img: './d_img/art/art9.jpg',
                title: '斬龍山遺址文化公園&emsp;現地探索特別場',
                text: '新北市土城區第1座遺址公園推出實境遊戲《遺落夢境》，只需攜帶行動裝置上網進入網頁，跟隨故事情境現地探索遺址文化內涵！9/18推出導覽特別場，現場破關即可兌換小禮物，還能欣賞街頭藝人的精采演出！<span class="font-semibold text-ntpcactive">＊因應疫情延後辦理</span>',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.facebook.com/e7summer/'
            }
        ]

        return { Arts }
    }
}
</script>