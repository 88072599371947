<template>
<header class="z-10 absolute w-full  bg-gradient-to-t from-navbluedark to-navblue">
    <nav class="flex flex-row py-1 md:py-1 items-center px-5 transform-all max-w-screen-2xl mx-auto">
        <!-- Logo big/small -->
        <div class="mt-3 md:mt-2 ">
            <router-link :to="{ name: 'Home' }">
                <img class="h-10 md:h-11" src="../images/2021.svg" alt="Home">
            </router-link>
        </div>

        <!-- desktop bar right side menu-->
        <ul v-show="!mobile" class=" navigation mt-6 flex flex-1 justify-end items-center ">
            <li class="text-white text-sm mr-2">
                <a href="https://www.facebook.com/e7summer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" viewBox="0 0 24.142 24.142">
                    <path id="Icon_awesome-facebook-square" data-name="Icon awesome-facebook-square" d="M21.555,2.25H2.587A2.587,2.587,0,0,0,0,4.837V23.805a2.587,2.587,0,0,0,2.587,2.587h7.4V18.184H6.588V14.321H9.983V11.376c0-3.349,1.994-5.2,5.048-5.2a20.567,20.567,0,0,1,2.992.261V9.725H16.337a1.932,1.932,0,0,0-2.178,2.087v2.508h3.706l-.593,3.863H14.159v8.208h7.4a2.587,2.587,0,0,0,2.587-2.587V4.837A2.587,2.587,0,0,0,21.555,2.25Z" transform="translate(0 -2.25)" fill="#fffced"/>
                    </svg>
                </a>
            </li>
            <li class="text-white text-sm mr-2">
                <a href="https://www.instagram.com/newtaipei_culture/?hl=es" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 24.142 24.142">
                    <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M12.068,8.118a6.188,6.188,0,1,0,6.188,6.188A6.178,6.178,0,0,0,12.068,8.118Zm0,10.211a4.023,4.023,0,1,1,4.023-4.023A4.03,4.03,0,0,1,12.068,18.329ZM19.953,7.864A1.443,1.443,0,1,1,18.51,6.421,1.44,1.44,0,0,1,19.953,7.864Zm4.1,1.465A7.143,7.143,0,0,0,22.1,4.272a7.19,7.19,0,0,0-5.057-1.95c-1.993-.113-7.966-.113-9.958,0A7.18,7.18,0,0,0,2.029,4.267,7.166,7.166,0,0,0,.08,9.324c-.113,1.993-.113,7.966,0,9.958a7.143,7.143,0,0,0,1.95,5.057,7.2,7.2,0,0,0,5.057,1.95c1.993.113,7.966.113,9.958,0a7.143,7.143,0,0,0,5.057-1.95,7.19,7.19,0,0,0,1.95-5.057c.113-1.993.113-7.96,0-9.953ZM21.477,21.42a4.073,4.073,0,0,1-2.294,2.294c-1.589.63-5.359.485-7.115.485s-5.531.14-7.115-.485A4.073,4.073,0,0,1,2.659,21.42c-.63-1.589-.485-5.359-.485-7.115s-.14-5.531.485-7.115A4.073,4.073,0,0,1,4.954,4.9c1.589-.63,5.359-.485,7.115-.485s5.531-.14,7.115.485a4.073,4.073,0,0,1,2.294,2.294c.63,1.589.485,5.359.485,7.115S22.107,19.837,21.477,21.42Z" transform="translate(0.005 -2.238)" fill="#fffced"/>
                    </svg>
                </a>
            </li>
            <li class="text-white text-sm mr-5">
                <a href="https://www.youtube.com/user/ntpcculture" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg"  height="13" viewBox="0 0 27.862 19.591">
                    <path id="Icon_awesome-youtube" data-name="Icon awesome-youtube" d="M28.33,7.565a3.5,3.5,0,0,0-2.463-2.479C23.694,4.5,14.981,4.5,14.981,4.5S6.268,4.5,4.1,5.086A3.5,3.5,0,0,0,1.632,7.565a36.726,36.726,0,0,0-.582,6.75,36.726,36.726,0,0,0,.582,6.75A3.449,3.449,0,0,0,4.1,23.5c2.173.586,10.886.586,10.886.586s8.713,0,10.886-.586a3.449,3.449,0,0,0,2.463-2.44,36.726,36.726,0,0,0,.582-6.75A36.726,36.726,0,0,0,28.33,7.565Zm-16.2,10.893V10.172l7.282,4.143-7.282,4.143Z" transform="translate(-1.05 -4.5)" fill="#fffced"/>
                    </svg>
                </a>
            </li>
            <li class="text-white text-xs font-light mr-6">
                <a href="https://www.boch.gov.tw/" target="_blank">文化部文化資產局</a>
            </li>
            <li class=" text-white text-xs font-light">
                <a href="https://www.culture.ntpc.gov.tw/" target="_blank">新北市文化局</a>
            </li>
        </ul>

        <!-- mobile menu -->
        <div class="icon md:hidden mt-5 flex flex-1 justify-end">
            <MenuIcon @click="toggleMobileNav" v-show="mobile" :class="{ 'icon-active' : mobileNav }" class="md:hidden block h-8 text-white cursor-pointer"></MenuIcon>
        </div>
        <transition name="mobile-nav z-20" class="pt-12 bg-gradient-to-t from-navbluedark to-navblue">
            <ul v-show="mobileNav" class="dropdown-nav text-gray-600 flex flex-col fixed  h-full w-4/5 bg-white left-0 top-0">
                <li class="flex justify-center">
                    <router-link @click="toggleMobileNav" :to="{ name: 'Home' }">
                        <img class="h-11" src="../images/2021.svg" alt="Home">
                    </router-link>
                </li>
                <li class="block text-center pt-8">
                    <router-link @click="toggleMobileNav" class="link text-ntpccolor" :to="{ name: 'Travel' }">
                        <span class="font-etitle text-white text-xl">Travel</span>
                        <p class="tracking-widest text-white text-xl">綜覽新北</p>
                    </router-link>
                </li>
                <li class="block text-center pt-8">
                    <router-link @click="toggleMobileNav" class="link text-ntpccolor" :to="{ name: 'Explore' }">
                        <span class="font-etitle text-white text-xl">Explore</span>
                        <p class="tracking-widest text-white text-xl">微觀新北</p>
                    </router-link>
                </li>
                <li class="block text-center pt-8">
                    <router-link @click="toggleMobileNav" class="link text-ntpccolor" :to="{ name: 'Art' }">
                        <span class="font-etitle text-white text-xl">Art</span>
                        <p class="tracking-widest text-white text-xl">藝探新北</p>
                    </router-link>
                </li>
                <li class="block text-center pt-8">
                    <router-link @click="toggleMobileNav" class="link text-ntpccolor" :to="{ name: 'Fun' }">
                        <span class="font-etitle text-white text-xl">Fun</span>
                        <p class="tracking-widest text-white text-xl">奇趣新北</p>
                    </router-link>
                </li>
            </ul>
        </transition>
    </nav>
</header>
<!-- Desktop content menu -->
<div class="z-10 relative hidden md:block mt-10">
    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 flex justify-center items-center mt-8">
        <ul class="flex flex-row space-x-16 leading-4">
            <li class="block text-center">
                <router-link active-class="active" class="link text-ntpccolor" :to="{ name: 'Travel' }">
                    <span class="text-xl font-etitle">Travel</span>
                    <p class="tracking-widest">綜覽新北</p>
                </router-link>
            </li>
            <li class="block text-center">
                <router-link active-class="active" class="link text-ntpccolor" :to="{ name: 'Explore' }">
                    <span class="text-xl font-etitle">Explore</span>
                    <p class="tracking-widest">微觀新北</p>
                </router-link>
            </li>
            <li class="block text-center">
                <router-link active-class="active" class="link text-ntpccolor" :to="{ name: 'Art' }">
                    <span class="text-xl font-etitle">Art</span>
                    <p class="tracking-widest">藝探新北</p>
                </router-link>
            </li>
            <li class="block text-center">
                <router-link active-class="active" class="link text-ntpccolor" :to="{ name: 'Fun' }">
                    <span class="text-xl font-etitle">Fun</span>
                    <p class="-widest">奇趣新北</p>
                </router-link>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import { MenuIcon } from '@heroicons/vue/solid'
export default {
    components: { MenuIcon },
    name: 'navigation',
    data() {
        return {
            scrollPosition: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null
        }
    },
    created() {
        window.addEventListener('resize', this.checkScreen)
        this.checkScreen()
    },
    methods: {
        toggleMobileNav() {
            this.mobileNav = !this.mobileNav
        },
        checkScreen() {
            this.windowWidth = window.innerWidth
            if(this.windowWidth <= 765) {
                this.mobile = true
                return
            }
            this.mobile = false
            this.mobileNav = false
            return
        }
    }
}
</script>