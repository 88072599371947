<template>
<div class="tour max-w-screen-2xl flex justify-center mx-auto">
    <div class="max-w-screen-lg mb-20">
        <h1 class="pt-28 md:pt-44 text-center text-ntpccolor text-2xl md:text-3xl font-semibold">
            專業團帶隊，文資小旅行
        </h1>
        <p class="w-11/12 md:w-2/3 mx-auto pt-6 text-footerlink">
            全新策劃文資小旅行路線，從故事採集、田調的再梳理，在地文史工作者的專業帶領之下，勾起觀者對在地的想像力與回憶，增加土地認同及深度了解文資地景。
            <span class=" font-semibold text-ntpcactive">＊實體活動配合新北市防疫政策視情況延後辦理或取消</span>
        </p>

        <!------ for in ------>
        <div class="px-4 md:px-12 pt-12 flex justify-center mx-auto">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-10 w-full">
                <div v-for="(spot, index) in Spots" :key="index" class="overflow-hidden">
                    <div class="relative">
                        <span class="absolute bottom-1 left-1 text-xs text-white">{{ spot.copyright }}</span>
                        <img :src="spot.img" alt="" class="overflow-hidden object-cover h-280 w-full">
                    </div>
                    <div class="relative md:h-380">
                        <h2 class="pt-6 text-ntpccolor text-lg font-semibold">{{ spot.title }}</h2>
                        <p v-html="spot.text" class="pt-3 text-justify"></p>
                        <div class="md:absolute bottom-0 w-full">
                            <hr class="mt-5 border-gray-300">
                            <div class="flex items-center py-2">
                                <EyeIcon class="h-6 text-ntpcactive" />
                                <a :href="spot.link" target="_blank" rel="noopener noreferrer">
                                    <span class="pl-3 text-sm text-ntpccolor font-semibold underline">{{ spot.icontext }}</span>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="px-4 pt-10 pb-16 md:px-12">
            <div class="video mt-10 overflow-hidden">
                <img class="w-full object-cover h-470 overflow-hidden " src="../images/tour/tour7.jpg" alt="">
            </div>
            <h2 class=" pt-6 text-ntpccolor text-lg font-semibold">家族宅第輕旅行</h2>
            <p class=" text-justify pt-2">
                新北市五股守讓堂為典型三合院，是在地聞人吳愚家族的起家厝，2006年登錄為歷史建築。其建材用磚、裝飾，具有時代及地域代表性，彰顯觀音山下發展的軌跡開發。古蹟日特別企劃以五股、蘆洲兩地的在地家族包含吳氏家族、李氏家族等，以「家族宅第」為題，將五股守讓堂、蘆洲李宅、蘆洲八角樓 、蘆洲保和宮，四點串聯成一條「五股、蘆洲在地家族發展」、「閩南建築變化」文化導覽的路線。
            </p>
            <hr class=" mt-5 border-gray-300">
            <div class="flex items-center py-2">
                <EyeIcon class="h-6 text-ntpcactive" />
                <a href="https://www.culture.ntpc.gov.tw/wgsrh" target="_blank">
                    <span class="pl-3 text-sm text-ntpccolor hover:text-ntpcactive font-semibold underline">活動點這裡</span>
                </a>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import { EyeIcon } from '@heroicons/vue/solid'
export default {
    name: 'GuidedTour',
    components: { EyeIcon },
    setup() {
        const Spots = [
            {
                img: './d_img/tour/tour1.jpg',
                title: '新北文資趴趴走',
                text: '今年暑假適逢疫情期間，待在家中快悶壞了嗎？新北市文化局響應全國古蹟日，於淡水、金山、中和、新店、貢寮等區推出文資小旅行，讓我們走出戶外，擁抱文資吧！',
                copyright: '力麗慈善事業基金會收錄',
                icontext: '活動點這裡',
                link: 'https://www.beclass.com/rid=25464886124acff3e9a2'
            },
            {
                img: './d_img/tour/tour2.jpg',
                title: '八里左岸 x 環境人文 x 淨灘趣',
                text: '十三行博物館預計在八里挖仔尾海灘舉辦「八里左岸x環境人文x淨灘趣」 ，帶領學員從海邊望向觀音山，認識八里的千年歷史與環境變遷，同時結合淨灘實作，一起守護這片古老海岸的永續發展。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.sshm.ntpc.gov.tw/'
            },
            {
                img: './d_img/tour/tour3.jpg',
                title: '水金九行路人導覽活動',
                text: '黃金博物館邀請在地導覽達人專業解說，帶領民眾探遊水金九地區礦業遺址。包括水圳橋、戰俘營舊址、日式宿舍遣址、八角亭舊址等文資景點，了解水金九地區人文與歷史故事。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.facebook.com/goldmuseum/'
            },
            {
                img: './d_img/tour/tour4.jpg',
                title: '古蹟萬花筒-淡水文化輕旅行',
                text: '你可知道淡水是北臺灣古蹟分布密度名列前茅的城鎮？為響應全國古蹟日，淡水古蹟博物館規劃專屬導覽路線，推出「古蹟萬花筒—淡水文化輕旅 行」免費導覽活動。讓我們一起穿梭淡水河港山城，從紅毛城到重建街、從漢文化到多元文化，探訪世界文化遺留在淡水的足跡吧!',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.tshs.ntpc.gov.tw/'
            },
            {
                img: './d_img/tour/tour5.jpg',
                title: '林園古蹟日—古城走走',
                text: '「林園古蹟日-古城走走」散步板橋古城，邀請地方文史老師，一起走訪板橋城的建造歷史，穿越林家花園周圍老街道，找尋在地商家、廟宇的文化脈絡，發現板橋古城與北台灣經濟發展的時代變遷。穿梭新舊交移之間，原來板橋有這麼浪漫的古城區。',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.linfamily.ntpc.gov.tw/'
            },
            {
                img: './d_img/tour/tour6.jpg',
                title: '空軍三重一村園區導覽',
                text: '空軍三重一村為北部僅存之防砲眷村，保有日治時期構築的防砲陣地及地下甬道遺跡，2006年登錄為歷史建築，為新北市眷村類文化資產首例。2018年完成修復，化身「新北市眷村文化園區」對外開放。110/9/18(六)、9/19(日)14:00推出園區導覽，1場約15分鐘，歡迎至遊客中心現場預約報名。<span class="font-semibold text-ntpcactive">＊防疫期間配合館舍開放時間</span>',
                icon: 'EyeIcon',
                icontext: '活動點這裡',
                link: 'https://www.culture.ntpc.gov.tw/military_village/xmdoc?XsmSId=0J214542360686000251'
            }
        ]

        return { Spots }
    }
}
</script>